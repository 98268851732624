import React, { Component } from "react";
import Helmet from "../Helmet";
import Home from "../Home";
import RetourCadeaux from "../RetourCadeaux";
import Footer from "../Footer";
import Bounce from "react-reveal/Bounce";
import Fade from "react-reveal/Fade";
import { NavLink } from "react-router-dom";
import voyage from "../../img/voyage.png";

class Cadeau8 extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <Helmet
          title={"Cadeau8 Noël"}
          link={"http://noel.guillaumequeste.fr/cadeau8"}
        />
        <Home />
        <RetourCadeaux />
        <div className="accueilCadeau">
          <h2 className="titreVoyage">Un voyage où tu veux !!!</h2>
          <img src={voyage} alt="voyage" className="voyage hithere" />
        </div>

        <Footer cadeaux={true} />
      </div>
    );
  }
}

export default Cadeau8;
