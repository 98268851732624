import React, { Component } from "react";
import Helmet from "../Helmet";
import Home from "../Home";
import RetourCadeaux from "../RetourCadeaux";
import Footer from "../Footer";
import Bounce from "react-reveal/Bounce";
import Fade from "react-reveal/Fade";
import { NavLink } from "react-router-dom";
import cheval from "../../img/cheval.png";

class Cadeau2 extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <Helmet
          title={"Cadeau2 Noël"}
          link={"http://noel.guillaumequeste.fr/cadeau2"}
        />
        <Home />
        <RetourCadeaux />
        <div className="accueilCadeau">
          <img src={cheval} alt="cheval" className="cheval swing" />
        </div>

        <Footer cadeaux={true} />
      </div>
    );
  }
}

export default Cadeau2;
