import React, { Component } from "react";
import Footer from "./Footer";
import { Link } from "react-router-dom";


class RetourCadeaux extends Component {
  render() {
    return (
      <div>
        <Footer />
        <Link to="/accueilCadeaux" className="retourCadeaux">
          <div className="divRetourCadeaux">
            <p>Retour</p>
          </div>
        </Link>
      </div>
    );
  }
}

export default RetourCadeaux;
