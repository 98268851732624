import React, { Component } from 'react'
import {Helmet} from "react-helmet"

class HelmetComponent extends Component {
  
    render () {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.props.title}</title>
                    <link rel="canonical" href={this.props.link} />
                    <meta name="keywords" content="Noël en famille" />
                    <meta name="description" content="oël en famille" />
                    <meta property="og:title" content="oël en famille" />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://noel.guillaumequeste.fr" />
                    <meta property="og:image" content="" />
                    <meta property="og:locale" content="fr_FR" />
                    <meta property="og:title" content="Noël en famille" />
                    <meta property="og:description" content="Noël en famille" />
                    <meta property="og:site_name" content="Noël en famille" />
                    <meta name="twitter:description" content="Noël en famille" />
                    <meta name="twitter:title" content="Noël en famille" />
                    <meta name="twitter:image" content="" />
                </Helmet>
            </div>
        )
    }
}

export default HelmetComponent