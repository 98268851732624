import React from "react";
import "./App.css";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Helmet from "./components/Helmet";
import { Link } from "react-router-dom";
import star_blue from "./img/star_blue.png";

const App = () => (
  <>
    <Helmet title={"Accueil Noël"} link={"http://noel.guillaumequeste.fr"} />

    <Home />

    <div className="app">
      <img src={star_blue} alt="stars_white" className="star_blue" />
      <img src={star_blue} alt="stars_white" className="star_blue2" />
      <img src={star_blue} alt="stars_white" className="star_blue3" />
      <div className="liens">
        <Link to="/accueilSouvenirs" className="lien">
          <div className="titleLien">
            <div className="lienSouvenrs">Souvenirs</div>
          </div>
        </Link>
        <Link to="/accueilCadeaux" className="lien">
          <div className="titleLien">
            <div className="lienCadeaux">Cadeaux</div>
          </div>
        </Link>
      </div>
      <Footer />
    </div>
  </>
);

export default App;
