import React, { Component } from "react";
import Footer from "./Footer";
import { Link } from "react-router-dom";


class Home extends Component {
  render() {
    return (
      <div>
        <Footer />
        <Link to="/" className="home">
          <div className="divHome">
            <p>Accueil</p>
          </div>
        </Link>
      </div>
    );
  }
}

export default Home;
