import React, { Component } from "react";
import Helmet from "../Helmet";
import Home from "../Home";
import RetourCadeaux from "../RetourCadeaux";
import Footer from "../Footer";
import Bounce from "react-reveal/Bounce";
import Fade from "react-reveal/Fade";
import { NavLink } from "react-router-dom";
import fontaine from "../../img/fontaine.png";
import gateau1 from "../../img/gateau1.png";
import bonbons1 from "../../img/bonbons1.png";
import gateau2 from "../../img/gateau2.png";
import bonbons2 from "../../img/bonbons2.png";
import canne1 from "../../img/canne1.png";
import canne2 from "../../img/canne2.png";
import sucette from "../../img/sucette.png";

class Cadeau4 extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <Helmet
          title={"Cadeau4 Noël"}
          link={"http://noel.guillaumequeste.fr/cadeau4"}
        />
        <Home />
        <RetourCadeaux />
        <div className="accueilCadeau accueilCadeau4">
          <img src={fontaine} alt="fontaine" className="fontaine" />
          <img src={gateau1} alt="gateau1" className="gateau1" />
          <img src={bonbons1} alt="bonbons1" className="bonbons1" />
          <img src={gateau2} alt="gateau2" className="gateau2" />
          <img src={bonbons2} alt="bonbons2" className="bonbons2" />
          <img src={sucette} alt="sucette" className="sucette" />
          <img src={canne2} alt="canne2" className="canne2" />
          <img src={canne1} alt="canne1" className="canne1" />
        </div>

        <Footer cadeaux={true} />
      </div>
    );
  }
}

export default Cadeau4;
