import React, { Component } from "react";
import Helmet from "../Helmet";
import Home from "../Home";
import RetourCadeaux from "../RetourCadeaux";
import Footer from "../Footer";
import Bounce from "react-reveal/Bounce";
import Fade from "react-reveal/Fade";
import { NavLink } from "react-router-dom";
import pere_noel from "../../img/pere_noel.jpg";

class Cadeau12 extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <Helmet
          title={"Cadeau12 Noël"}
          link={"http://noel.guillaumequeste.fr/cadeau12"}
        />
        <Home />
        <RetourCadeaux />
        <div className="accueilCadeau accueilCadeau12">
          <h3 className="rdv">Tu as gagné un rendez-vous avec le Père-Noël !!!</h3>
        </div>

        <Footer cadeaux={true} />
      </div>
    );
  }
}

export default Cadeau12;
