import React, { Component } from "react";
import Helmet from "../Helmet";
import Home from "../Home";
import Footer from "../Footer";
import Bounce from "react-reveal/Bounce";
import Fade from "react-reveal/Fade";
import { NavLink } from "react-router-dom";
import star_blue from "../../img/star_blue.png";

class AccueilSouvenirs extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <Helmet
          title={"Accueil souvenirs Noël"}
          link={"http://noel.guillaumequeste.fr/souvenirs"}
        />
        <Home />

        <div className="accueilSouvenirs">
          <img src={star_blue} alt="stars_white" className="star_blue" />
          <img src={star_blue} alt="stars_white" className="star_blue2" />
          <img src={star_blue} alt="stars_white" className="star_blue4" />
          <div className="titreAccueil">
            <Bounce top>
              <h5 className="h5TitreAccueil">Souvenirs</h5>
            </Bounce>
          </div>
          <Fade right>
            <NavLink to="/2020" className="rubrique">
              <h6 className="titreRubrique">2020</h6>
            </NavLink>
          </Fade>
          <Fade right>
            <NavLink to="/2019" className="rubrique">
              <h6 className="titreRubrique">2019</h6>
            </NavLink>
          </Fade>
          <Fade right>
            <NavLink to="/2018" className="rubrique">
              <h6 className="titreRubrique">2018</h6>
            </NavLink>
          </Fade>
          <Fade right>
            <NavLink to="/2017" className="rubrique">
              <h6 className="titreRubrique">2017</h6>
            </NavLink>
          </Fade>
          <Fade right>
            <NavLink to="/2016" className="rubrique">
              <h6 className="titreRubrique">2016</h6>
            </NavLink>
          </Fade>
          <Fade right>
            <NavLink to="/2015" className="rubrique">
              <h6 className="titreRubrique">2015</h6>
            </NavLink>
          </Fade>
          <Fade right>
            <NavLink to="/2014" className="rubrique">
              <h6 className="titreRubrique">2014</h6>
            </NavLink>
          </Fade>
          <Fade right>
            <NavLink to="/2013" className="rubrique">
              <h6 className="titreRubrique">2013</h6>
            </NavLink>
          </Fade>
          <Fade right>
            <NavLink to="/2012" className="rubrique">
              <h6 className="titreRubrique">2012</h6>
            </NavLink>
          </Fade>
          <Fade right>
            <NavLink to="/2011" className="rubrique">
              <h6 className="titreRubrique">2011</h6>
            </NavLink>
          </Fade>
          <Fade right>
            <NavLink to="/2010" className="rubrique">
              <h6 className="titreRubrique">2010</h6>
            </NavLink>
          </Fade>
          <Fade right>
            <NavLink to="/2009" className="rubrique">
              <h6 className="titreRubrique">2009</h6>
            </NavLink>
          </Fade>
          <Fade right>
            <NavLink to="/2008" className="rubrique">
              <h6 className="titreRubrique">2008</h6>
            </NavLink>
          </Fade>
        </div>
        <Footer souvenirs={true} />
      </div>
    );
  }
}

export default AccueilSouvenirs;
