import React, { Component } from "react";
import Helmet from "../Helmet";
import Home from "../Home";
import RetourCadeaux from "../RetourCadeaux";
import Footer from "../Footer";
import Bounce from "react-reveal/Bounce";
import Fade from "react-reveal/Fade";
import { NavLink } from "react-router-dom";
import notfound from "../../img/notfound.gif";

class Cadeau7 extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <Helmet
          title={"Cadeau7 Noël"}
          link={"http://noel.guillaumequeste.fr/cadeau7"}
        />
        <Home />
        <RetourCadeaux />
        <div className="accueilCadeau">
        <h2 className="desole">Désolé, il n'y a pas de cadeau ici.</h2>
          <img src={notfound} alt="notfound" className="notfound" />
        </div>

        <Footer cadeaux={true} />
      </div>
    );
  }
}

export default Cadeau7;
