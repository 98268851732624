import React, { Component } from "react";
import Helmet from "../Helmet";
import Home from "../Home";
import RetourSouvenirs from "../RetourSouvenirs";
import Footer from "../Footer";
import Bounce from "react-reveal/Bounce";
import Fade from "react-reveal/Fade";
import p1 from "../../img/2020/2020-01.jpg";
import p2 from "../../img/2020/2020-02.jpg";
import p3 from "../../img/2020/2020-03.jpg";
import p4 from "../../img/2020/2020-04.jpg";
import p5 from "../../img/2020/2020-05.jpg";
import p6 from "../../img/2020/2020-06.jpg";
import p7 from "../../img/2020/2020-07.jpg";
import p8 from "../../img/2020/2020-08.jpg";
import p9 from "../../img/2020/2020-09.jpg";
import p10 from "../../img/2020/2020-10.jpg";
import p11 from "../../img/2020/2020-11.jpg";
import p12 from "../../img/2020/2020-12.jpg";
import p13 from "../../img/2020/2020-13.jpg";
import p14 from "../../img/2020/2020-14.jpg";
import star_blue from "../../img/star_blue.png";

class Annee2020 extends Component {
  render() {
    return (
      <div>
        <Helmet
          title={"2020 Noël"}
          link={"http://noel.guillaumequeste.fr/2019"}
        />

        <Home />
        <RetourSouvenirs />
        <div className="annee">
          <img src={star_blue} alt="stars_white" className="star_blue" />
          <img src={star_blue} alt="stars_white" className="star_blue2" />
          <img src={star_blue} alt="stars_white" className="star_blue4" />
          <div className="titreAccueil">
            <Bounce top>
              <h5 className="h5TitreAccueil">2020</h5>
            </Bounce>
          </div>
          <div className="photosPage">
            <Fade right>
              <div className="divPhotoPage">
                <img src={p1} alt="2020-01" className="photoPageHorizontale" />
              </div>
            </Fade>
            <Fade right>
              <div className="divPhotoPage">
                <img src={p2} alt="2020-02" className="photoPageVerticale" />
              </div>
            </Fade>
            <Fade right>
              <div className="divPhotoPage">
                <img src={p3} alt="2020-03" className="photoPageHorizontale" />
              </div>
            </Fade>
          </div>
          <div className="photosPage">
            <Fade right>
              <div className="divPhotoPage">
                <img src={p4} alt="2020-04" className="photoPageVerticale" />
              </div>
            </Fade>
            <Fade right>
              <div className="divPhotoPage">
                <img src={p5} alt="2020-05" className="photoPageHorizontale" />
              </div>
            </Fade>
            <Fade right>
              <div className="divPhotoPage">
                <img src={p6} alt="2020-06" className="photoPageHorizontale" />
              </div>
            </Fade>
          </div>
          <div className="photosPage">
            <Fade right>
              <div className="divPhotoPage">
                <img src={p7} alt="2020-07" className="photoPageHorizontale" />
              </div>
            </Fade>
            <Fade right>
              <div className="divPhotoPage">
                <img src={p8} alt="2020-08" className="photoPageHorizontale" />
              </div>
            </Fade>
            <Fade right>
              <div className="divPhotoPage">
                <img src={p9} alt="2020-09" className="photoPageHorizontale" />
              </div>
            </Fade>
          </div>
          <div className="photosPage">
            <Fade right>
              <div className="divPhotoPage">
                <img src={p10} alt="2020-10" className="photoPageHorizontale" />
              </div>
            </Fade>
            <Fade right>
              <div className="divPhotoPage">
                <img src={p11} alt="2020-11" className="photoPageHorizontale" />
              </div>
            </Fade>
            <Fade right>
              <div className="divPhotoPage">
                <img src={p12} alt="2020-12" className="photoPageHorizontale" />
              </div>
            </Fade>
          </div>
          <div className="photosPage">
            <Fade right>
              <div className="divPhotoPage">
                <img src={p13} alt="2020-13" className="photoPageHorizontale" />
              </div>
            </Fade>
            <Fade right>
              <div className="divPhotoPage">
                <img src={p14} alt="2020-14" className="photoPageHorizontale" />
              </div>
            </Fade>
          </div>
          <Footer souvenirs={true} />
        </div>
      </div>
    );
  }
}

export default Annee2020;
