import React, { Component } from "react";
import Helmet from "../Helmet";
import Home from "../Home";
import Footer from "../Footer";
import Bounce from "react-reveal/Bounce";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import cadeau1 from "../../img/cadeau1.png";
import cadeau2 from "../../img/cadeau2.png";
import cadeau3 from "../../img/cadeau3.png";
import cadeau4 from "../../img/cadeau4.png";
import cadeau5 from "../../img/cadeau5.png";
import cadeau6 from "../../img/cadeau6.png";
import cadeau7 from "../../img/cadeau7.png";
import cadeau8 from "../../img/cadeau8.png";
import cadeau9 from "../../img/cadeau9.png";
import cadeau10 from "../../img/cadeau10.png";
import cadeau11 from "../../img/cadeau11.png";
import cadeau12 from "../../img/cadeau12.png";

class AccueilCadeaux extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <Helmet
          title={"Accueil cadeaux Noël"}
          link={"http://noel.guillaumequeste.fr/cadeaux"}
        />
        <Home />

        <div className="accueilCadeaux">
          <Link to="/cadeau1">
            <img src={cadeau1} alt="cadeau1" className="cadeau1 cadeau" />
          </Link>
          <Link to="/cadeau2">
            <img src={cadeau2} alt="cadeau2" className="cadeau2 cadeau" />
          </Link>
          <Link to="/cadeau3">
            <img src={cadeau3} alt="cadeau3" className="cadeau3 cadeau" />
          </Link>
          <Link to="/cadeau4">
            <img src={cadeau4} alt="cadeau4" className="cadeau4 cadeau" />
          </Link>
          <Link to="/cadeau5">
            <img src={cadeau5} alt="cadeau5" className="cadeau5 cadeau" />
          </Link>
          <Link to="/cadeau6">
            <img src={cadeau6} alt="cadeau6" className="cadeau6 cadeau" />
          </Link>
          <Link to="/cadeau7">
            <img src={cadeau7} alt="cadeau7" className="cadeau7 cadeau" />
          </Link>
          <Link to="/cadeau8">
            <img src={cadeau8} alt="cadeau8" className="cadeau8 cadeau" />
          </Link>
          <Link to="/cadeau9">
            <img src={cadeau9} alt="cadeau9" className="cadeau9 cadeau" />
          </Link>
          <Link to="/cadeau10">
            <img src={cadeau10} alt="cadeau10" className="cadeau10 cadeau" />
          </Link>
          <Link to="/cadeau11">
            <img src={cadeau11} alt="cadeau11" className="cadeau11 cadeau" />
          </Link>
          <Link to="/cadeau12">
            <img src={cadeau12} alt="cadeau12" className="cadeau12 cadeau" />
          </Link>
        </div>

        <Footer cadeaux={true} />
      </div>
    );
  }
}

export default AccueilCadeaux;
