import React, { Component } from "react";
import Helmet from "../Helmet";
import Home from "../Home";
import RetourCadeaux from "../RetourCadeaux";
import Footer from "../Footer";
import Bounce from "react-reveal/Bounce";
import Fade from "react-reveal/Fade";
import { NavLink } from "react-router-dom";
import coeur from "../../img/coeur.png";
import coeurs1 from "../../img/coeurs1.png";
import coeurs2 from "../../img/coeurs2.png";

class Cadeau5 extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <Helmet
          title={"Cadeau5 Noël"}
          link={"http://noel.guillaumequeste.fr/cadeau5"}
        />
        <Home />
        <RetourCadeaux />
        <div className="accueilCadeau">
          <h1 className="titreAmour">Beaucoup d'amour !!!</h1>
          <img src={coeur} alt="coeur" className="coeur" />
          <img src={coeur} alt="coeur2" className="coeur2" />
          <img src={coeur} alt="coeur3" className="coeur3" />
          <img src={coeur} alt="coeur4" className="coeur4" />
          <img src={coeurs1} alt="coeurs1" className="coeurs1" />
          <img src={coeurs2} alt="coeurs2" className="coeurs2" />
        </div>

        <Footer cadeaux={true} />
      </div>
    );
  }
}

export default Cadeau5;
