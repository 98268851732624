import React, { Component } from "react";
import Helmet from "../Helmet";
import Home from "../Home";
import RetourCadeaux from "../RetourCadeaux";
import Footer from "../Footer";
import Bounce from "react-reveal/Bounce";
import Fade from "react-reveal/Fade";
import { NavLink } from "react-router-dom";
import sac_mary_poppins from "../../img/sac_mary_poppins.png";
import mary_poppins from "../../img/mary_poppins.gif";

class Cadeau10 extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <Helmet
          title={"Cadeau10 Noël"}
          link={"http://noel.guillaumequeste.fr/cadeau10"}
        />
        <Home />
        <RetourCadeaux />
        <div className="accueilCadeau">
          <h2 className="titreSac">Le sac de Mary Poppins !!!</h2>
          <img src={sac_mary_poppins} alt="sac_mary_poppins" className="sac_mary_poppins" />
          <h2>Démonstration</h2>
          <img src={mary_poppins} alt="mary_poppins" className="mary_poppins" />
        </div>

        <Footer cadeaux={true} />
      </div>
    );
  }
}

export default Cadeau10;
