import React, { Component } from "react";
import Helmet from "../Helmet";
import Home from "../Home";
import RetourCadeaux from "../RetourCadeaux";
import Footer from "../Footer";
import Bounce from "react-reveal/Bounce";
import Fade from "react-reveal/Fade";
import { NavLink } from "react-router-dom";
import pull from "../../img/pull.png";

class Cadeau6 extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <Helmet
          title={"Cadeau6 Noël"}
          link={"http://noel.guillaumequeste.fr/cadeau6"}
        />
        <Home />
        <RetourCadeaux />
        <div className="accueilCadeau">
          <img src={pull} alt="pull" className="pull" />
        </div>

        <Footer cadeaux={true} />
      </div>
    );
  }
}

export default Cadeau6;
