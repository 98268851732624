import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import AccueilSouvenirs from './components/souvenirs/AccueilSouvenirs'
import Annee2020 from './components/souvenirs/Annee2020'
import Annee2019 from './components/souvenirs/Annee2019'
import Annee2018 from './components/souvenirs/Annee2018'
import Annee2017 from './components/souvenirs/Annee2017'
import Annee2016 from './components/souvenirs/Annee2016'
import Annee2015 from './components/souvenirs/Annee2015'
import Annee2014 from './components/souvenirs/Annee2014'
import Annee2013 from './components/souvenirs/Annee2013'
import Annee2012 from './components/souvenirs/Annee2012'
import Annee2011 from './components/souvenirs/Annee2011'
import Annee2010 from './components/souvenirs/Annee2010'
import Annee2009 from './components/souvenirs/Annee2009'
import Annee2008 from './components/souvenirs/Annee2008'

import AccueilCadeaux from './components/cadeaux/AccueilCadeaux'
import Cadeau1 from './components/cadeaux/Cadeau1'
import Cadeau2 from './components/cadeaux/Cadeau2'
import Cadeau3 from './components/cadeaux/Cadeau3'
import Cadeau4 from './components/cadeaux/Cadeau4'
import Cadeau5 from './components/cadeaux/Cadeau5'
import Cadeau6 from './components/cadeaux/Cadeau6'
import Cadeau7 from './components/cadeaux/Cadeau7'
import Cadeau8 from './components/cadeaux/Cadeau8'
import Cadeau9 from './components/cadeaux/Cadeau9'
import Cadeau10 from './components/cadeaux/Cadeau10'
import Cadeau11 from './components/cadeaux/Cadeau11'
import Cadeau12 from './components/cadeaux/Cadeau12'

import NotFound from './components/NotFound'

import ScrollToTop from './components/ScrollToTop'

const Root = () => (
    <Router>
        <ScrollToTop />
        <Switch>
            <Route exact path='/' component={App}/>

            <Route path='/accueilSouvenirs' component={AccueilSouvenirs}/>
            <Route path='/2020' component={Annee2020}/>
            <Route path='/2019' component={Annee2019}/>
            <Route path='/2018' component={Annee2018}/>
            <Route path='/2017' component={Annee2017}/>
            <Route path='/2016' component={Annee2016}/>
            <Route path='/2015' component={Annee2015}/>
            <Route path='/2014' component={Annee2014}/>
            <Route path='/2013' component={Annee2013}/>
            <Route path='/2012' component={Annee2012}/>
            <Route path='/2011' component={Annee2011}/>
            <Route path='/2010' component={Annee2010}/>
            <Route path='/2009' component={Annee2009}/>
            <Route path='/2008' component={Annee2008}/>

            <Route path='/accueilCadeaux' component={AccueilCadeaux}/>
            <Route path='/cadeau1' component={Cadeau1}/>
            <Route path='/cadeau2' component={Cadeau2}/>
            <Route path='/cadeau3' component={Cadeau3}/>
            <Route path='/cadeau4' component={Cadeau4}/>
            <Route path='/cadeau5' component={Cadeau5}/>
            <Route path='/cadeau6' component={Cadeau6}/>
            <Route path='/cadeau7' component={Cadeau7}/>
            <Route path='/cadeau8' component={Cadeau8}/>
            <Route path='/cadeau9' component={Cadeau9}/>
            <Route path='/cadeau10' component={Cadeau10}/>
            <Route path='/cadeau11' component={Cadeau11}/>
            <Route path='/cadeau12' component={Cadeau12}/>
            
            <Route path='/notfound' component={NotFound}/>
            <Redirect to="/notfound" />
        </Switch>
    </Router>
)


ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();