import React, { Component } from "react";
import Helmet from "../Helmet";
import Home from "../Home";
import RetourCadeaux from "../RetourCadeaux";
import Footer from "../Footer";
import Bounce from "react-reveal/Bounce";
import Fade from "react-reveal/Fade";
import { NavLink } from "react-router-dom";
import lampe from "../../img/lampe.png";
import genie from "../../img/genie.png";

class Cadeau11 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: false,
      comments: true
    };
    this.clicDetails = this.clicDetails.bind(this);
  }

  clicDetails() {
    this.setState({
      details: true,
      comments: false
    });
  }
  
  render() {
    let imgGenie = { display: "none"};
    let pComments = { display: "block" };

    if (this.state.details === true) {
      imgGenie.display = "block"
    }

    if (this.state.comments === false) {
      pComments.display = "none"
    }

    return (
      <div>
        <Helmet
          title={"Cadeau11 Noël"}
          link={"http://noel.guillaumequeste.fr/cadeau11"}
        />
        <Home />
        <RetourCadeaux />
        <div className="accueilCadeau">
          <h2 className="titreLampe">La lampe du génie !!!</h2>
          <img src={genie} alt="genie" className="genie" style={imgGenie}/>
          <img src={lampe} alt="lampe" className="lampe" onClick={this.clicDetails}/>
          <p style={pComments}>Clique sur la lampe pour la frotter</p>
        </div>

        <Footer cadeaux={true} />
      </div>
    );
  }
}

export default Cadeau11;
