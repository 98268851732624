import React, { Component } from 'react'
import Footer from './Footer'
import Helmet from './Helmet'
import { Link } from "react-router-dom"
import Home from "./Home";

class NotFound extends Component {
  
    render () {
        return (
            <div>
                <Helmet title={"NotFound Noël"}
                        link={"http://noel.guillaumequeste.fr/notfound"} />
                <Home />
                <Footer />
                <div className="notFound">
                    <div className="divNotFound">
                        <p>Page not found</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default NotFound