import React, { Component } from "react";
import Helmet from "../Helmet";
import Home from "../Home";
import RetourCadeaux from "../RetourCadeaux";
import Footer from "../Footer";
import Bounce from "react-reveal/Bounce";
import Fade from "react-reveal/Fade";
import { NavLink } from "react-router-dom";
import tresor from "../../img/tresor.png";
import pieces from "../../img/pieces.png";
import sac_dollars from "../../img/sac_dollars.png";
import lingots from "../../img/lingots.png";
import billets from "../../img/billets.png";
import tirelire from "../../img/tirelire.png";
import dollars from "../../img/dollars.png";

class Cadeau3 extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <Helmet
          title={"Cadeau3 Noël"}
          link={"http://noel.guillaumequeste.fr/cadeau3"}
        />
        <Home />
        <RetourCadeaux />
        <div className="accueilCadeau accueilCadeau3">
          <img src={tresor} alt="tresor" className="tresor" />
          <img src={pieces} alt="pieces" className="pieces" />
          <img src={sac_dollars} alt="sac_dollars" className="sac_dollars" />
          <img src={sac_dollars} alt="sac_dollars" className=" sac_dollars sac_dollars2" />
          <img src={lingots} alt="lingots" className=" lingots" />
          <img src={billets} alt="billets" className=" billets" />
          <img src={tirelire} alt="tirelire" className=" tirelire" />
          <img src={dollars} alt="dollars" className=" dollars" />
        </div>

        <Footer cadeaux={true} />
        
      </div>
    );
  }
}

export default Cadeau3;
