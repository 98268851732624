import React, { Component } from "react";
import Helmet from "../Helmet";
import Home from "../Home";
import RetourCadeaux from "../RetourCadeaux";
import Footer from "../Footer";
import Bounce from "react-reveal/Bounce";
import Fade from "react-reveal/Fade";
import { NavLink } from "react-router-dom";
import excitation from "../../img/excitation.gif";
import fleche from "../../img/fleche.png";
import orange from "../../img/orange.png";
import deception from "../../img/deception.gif";

class Cadeau1 extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <Helmet
          title={"Cadeau1 Noël"}
          link={"http://noel.guillaumequeste.fr/cadeau1"}
        />
        <Home />
        <RetourCadeaux />
        <div className="accueilCadeau">
          <h1>Un cadeau !!!</h1>
          <img src={excitation} alt="excitation" className="excitation" />
          <h1>Voir le cadeau</h1>
          <img src={fleche} alt="fleche" className="fleche" />
          <Fade right>
            <img src={orange} alt="orange" className="orange" />
          </Fade>
          <img src={deception} alt="deception" className="deception" />
        </div>

        <Footer cadeaux={true} />
      </div>
    );
  }
}

export default Cadeau1;
