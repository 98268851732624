import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="bodyFooter">
        <Link
          to="/accueilSouvenirs"
          className={`divFooter divFooter1 ${
            this.props.souvenirs == true ? "ecStyle" : ""
          }`}
        >
          <div className="linkFooter">
            <span className="colorLinkFooter">
              <p>Souvenirs</p>
            </span>
          </div>
        </Link>
        <Link
          to="/accueilCadeaux"
          className={`divFooter divFooter2 ${this.props.cadeaux == true ? "ecStyle" : ""}`}
        >
          <div className="linkFooter">
            <span className="colorLinkFooter">
              <p>Cadeaux</p>
            </span>
          </div>
        </Link>
      </div>
    );
  }
}

export default Footer;
