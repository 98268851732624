import React, { Component } from "react";
import Helmet from "../Helmet";
import Home from "../Home";
import RetourSouvenirs from "../RetourSouvenirs";
import Footer from "../Footer";
import Bounce from "react-reveal/Bounce";
import Fade from "react-reveal/Fade";
import pleurs from "../../img/pleurs.gif";
import star_blue from "../../img/star_blue.png";

class Annee2011 extends Component {
  render() {
    return (
      <div>
        <Helmet
          title={"2011 Noël"}
          link={"http://noel.guillaumequeste.fr/2011"}
        />

        <Home />
        <RetourSouvenirs />
        <div className="annee">
          <h2 className="titreAnnee">Malheureusement je n'ai pas de photos...</h2>
          <img src={pleurs} alt="pleurs" className="pleurs" />
          <Footer souvenirs={true} />
        </div>
      </div>
    );
  }
}

export default Annee2011;
