import React, { Component } from "react";
import Helmet from "../Helmet";
import Home from "../Home";
import RetourCadeaux from "../RetourCadeaux";
import Footer from "../Footer";
import Bounce from "react-reveal/Bounce";
import Fade from "react-reveal/Fade";
import { NavLink } from "react-router-dom";
import livres1 from "../../img/livres1.png";
import livres2 from "../../img/livres2.png";
import livres3 from "../../img/livres3.png";
import livres4 from "../../img/livres4.png";
import livres5 from "../../img/livres5.png";

class Cadeau9 extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <Helmet
          title={"Cadeau9 Noël"}
          link={"http://noel.guillaumequeste.fr/cadeau9"}
        />
        <Home />
        <RetourCadeaux />
        <div className="accueilCadeau accueilCadeau9">
          <h6 className="citation">"Les livres sont la lumière qui guide la civilisation",<br />Franklin Delano Roosevelt</h6>
          <img src={livres1} alt="livres1" className="livres1" />
          <img src={livres2} alt="livres2" className="livres2" />
          <img src={livres3} alt="livres3" className="livres3" />
          <img src={livres4} alt="livres4" className="livres4" />
          <img src={livres5} alt="livres5" className="livres5" />
        </div>

        <Footer cadeaux={true} />
      </div>
    );
  }
}

export default Cadeau9;
