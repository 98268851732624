import React, { Component } from "react";
import Helmet from "../Helmet";
import Home from "../Home";
import RetourSouvenirs from "../RetourSouvenirs";
import Footer from "../Footer";
import Bounce from "react-reveal/Bounce";
import Fade from "react-reveal/Fade";
import p1 from "../../img/2017/2017-01.jpg";
import p2 from "../../img/2017/2017-02.jpg";
import p3 from "../../img/2017/2017-03.jpg";
import p4 from "../../img/2017/2017-04.jpg";
import p5 from "../../img/2017/2017-05.jpg";
import p6 from "../../img/2017/2017-06.jpg";
import p7 from "../../img/2017/2017-07.jpg";
import p8 from "../../img/2017/2017-08.jpg";
import star_blue from "../../img/star_blue.png";

class Annee2017 extends Component {
  render() {
    return (
      <div>
        <Helmet
          title={"2017 Noël"}
          link={"http://noel.guillaumequeste.fr/2017"}
        />

        <Home />
        <RetourSouvenirs />
        <div className="annee">
          <img src={star_blue} alt="stars_white" className="star_blue" />
          <img src={star_blue} alt="stars_white" className="star_blue2" />
          <img src={star_blue} alt="stars_white" className="star_blue4" />
          <div className="titreAccueil">
            <Bounce top>
              <h5 className="h5TitreAccueil">2017</h5>
            </Bounce>
          </div>
          <div className="photosPage">
            <Fade right>
              <div className="divPhotoPage">
              <img src={p1} alt="2017-01" className="photoPageHorizontale" />
              </div>
            </Fade>
            <Fade right>
              <div className="divPhotoPage">
              <img src={p2} alt="2017-02" className="photoPageHorizontale" />
              </div>
            </Fade>
            <Fade right>
              <div className="divPhotoPage">
              <img src={p3} alt="2017-03" className="photoPageHorizontale" />
              </div>
            </Fade>
          </div>
          <div className="photosPage">
            <Fade right>
              <div className="divPhotoPage">
              <img src={p4} alt="2017-04" className="photoPageVerticale" />
              </div>
            </Fade>
            <Fade right>
              <div className="divPhotoPage">
              <img src={p5} alt="2017-05" className="photoPageVerticale" />
              </div>
            </Fade>
            <Fade right>
              <div className="divPhotoPage">
              <img src={p6} alt="2017-06" className="photoPageHorizontale" />
              </div>
            </Fade>
          </div>
          <div className="photosPage">
            <Fade right>
              <div className="divPhotoPage">
              <img src={p7} alt="2017-07" className="photoPageHorizontale" />
              </div>
            </Fade>
            <Fade right>
              <div className="divPhotoPage">
              <img src={p8} alt="2017-08" className="photoPageVerticale" />
              </div>
            </Fade>
          </div>
          <Footer souvenirs={true} />
        </div>
      </div>
    );
  }
}

export default Annee2017;
